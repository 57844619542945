
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  background-color: #000;
}

.btnn {
  height: 100px;
  width: 100px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid #000;
  background: #000;
  
}


::-webkit-scrollbar {
  width: 0.75em;
}

::-webkit-scrollbar-track {
  background: rgb(56, 56, 56);
  border-radius: 100vw;
  margin-block: 0.5em;
  
}

::-webkit-scrollbar-thumb {
  width: 0.75em;
  border-radius: 100vw;
  transform: scale(1.0) translateZ(0);
  filter: blur(50px);
  background: linear-gradient(
    to left,
    #ff5770,
    #e4428d,
    #c42da8,
    #9e16c3,
    #6501de,
    #9e16c3,
    #c42da8,
    #e4428d,
    #ff5770
  );
  background-size: 200% 200%;
  animation: animateGlow 2s linear infinite;
}

.wallet__container {
  display: wrap;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  justify-items: center;
  height: 350px;
  margin: auto;
  max-width: 350px;
  max-height: 800px;
}

.wallet__wrapper {
  background-color: #fff;
  display: wrap;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  margin: auto;
  padding: 2px;
  max-width: 350px;
}

.connect__container {
  display: wrap;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  justify-items: center;
  height: 350px;
  margin: auto;
  max-width: 150px;
  max-height: 1000px;
  min-height: 800px;
}

.connect__wrapper {
  background-color: #fff;
  display: wrap;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  margin: auto;
  padding: 2px;
  max-width: 350px;
  position:relative;               /* 2 */
  top: 50%;                         /* 3 */
  transform: translate(0, -50%);  /* 4 */

}

.modal__wrapper {
  background-color: #000;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1 1;
  align-items: center;
  margin: auto;
  padding: 2px;
  max-width: 350px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-left: 50%;
}

.walletnft__wrapper {
  background-color: #fff;
  flex: 0.5;
}

.nftdisplay__wrapper {
  background-color: #fff;
  flex: 1;
}

.share__wrapper {
  background-color: #fff;
  display: grid;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  margin: auto;
  padding: 2px;
  max-width: 350px;
  position:relative;               /* 2 */
  top: 50%;                         /* 3 */
  transform: translate(0, -50%);  /* 4 */
  z-index: 1;
  grid-template-columns: repeat(auto-fill,minmax(346px, 1fr));
}

.mint__container {
  display: grid;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  justify-items: center;
  margin: auto;
  max-width: 350px;
  align-content: space-evenly;
  min-height: 600px;
  grid-gap: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.mainrarity__container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.icon__small {
  position: relative;
  max-height: 25px;
  max-width: 25px;
  align-items: center;
  align-self: center;
}

  .connect__wrapper::after {
    position: absolute;
    content: "";
    top: 0px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    max-width: 350px;
    transform: scale(1.0) translateZ(0);
    filter: blur(50px);
    background: linear-gradient(
      to left,
      #ff5770,
      #e4428d,
      #c42da8,
      #9e16c3,
      #6501de,
      #9e16c3,
      #c42da8,
      #e4428d,
      #ff5770
    );
    background-size: 200% 200%;
    animation: animateGlow 2s linear infinite;
  }

  .walletnft__wrapper::after {
    position: absolute;
    content: "";
    top: 0px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    max-width: 350px;
    transform: scale(1.0) translateZ(0);
    filter: blur(50px);
    background: linear-gradient(
      to left,
      #ff5770,
      #e4428d,
      #c42da8,
      #9e16c3,
      #6501de,
      #9e16c3,
      #c42da8,
      #e4428d,
      #ff5770
    );
    background-size: 200% 200%;
    animation: animateGlow 2s linear infinite;
  }

  .trait__title {
    background-color: gray;
    display: grid;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    margin: auto;
    padding: 2px;
    max-width: 350px;
    grid-template-columns: repeat(auto-fill,minmax(100px, 1fr));
  }

  .trait__wrapper {
    background-color:black;
    border-radius: 5px;
    display: grid;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    margin: 5px;
    padding: 2px;
    max-width: 350px;
    grid-template-columns: repeat(auto-fill,minmax(100px, 1fr));
    justify-items: end;
    grid-template-columns: 2fr 0.1fr 3fr 0.1fr;
  }

  .nft__wrapper {
    display: grid;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    margin: auto;
    max-width: 350px;
    max-height: 150px;
    justify-items: center;
    overflow:scroll;
    overflow-x:hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
  }

  .nft2__wrapper {
    display: grid;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    margin: auto;
    padding: 2px;
    max-width: 100;
    max-height: 300px;
    justify-items: center;
    overflow:scroll;
    overflow-x:hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
  }

  .nft3__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    margin: auto;
    padding: 0px;
    max-height: 20px;
    justify-items: center;
    color: #fff;
    max-width: 100px;
    z-index: 0;
    background-color: transparent;
  }
  .nft3__wrapper-text {
    background-color: transparent;
  }


  .nftTrait__wrapper {
    color: #fff;
    justify-self: center;
    background-color: transparent;
  }

  .nftName__wrapper {
    color: #fff;
    justify-self:center;
    background-color: transparent;
    font-size: 15px;
  }

  .black__background{
    background-color: #000;
  }

  .nftScore__wrapper {
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    padding: 3px;
    max-height: 20px;
    justify-items: center;
    color: black;
    max-width: 50px;
    z-index: 0;
    font-size: 15px;
    min-width: 45px;
    align-content: space-around;
  }

  .nft4__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    margin: auto;
    padding: 0px;
    max-height: 20px;
    justify-items: center;
    color: #fff;
    z-index: 1;
    width: 100%;
    align-items: center;
    background: linear-gradient(
      to left,
      #ff5770,
      #e4428d,
      #c42da8,
      #9e16c3,
      #6501de,
      #9e16c3,
      #c42da8,
      #e4428d,
      #ff5770
    );
    background-size: 200% 200%;
    animation: animateGlow 2s linear infinite;
  }

  .common__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    margin: auto;
    padding: 0px;
    max-height: 20px;
    justify-items: center;
    color: #fff;
    z-index: 1;
    width: 100%;
    align-items: center;
    margin-top: 2px;
  }

  .rare__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    margin: auto;
    padding: 0px;
    max-height: 20px;
    justify-items: center;
    background: linear-gradient(
      to left,
      #0051ff,
      #437fff,
      #88adff,
      #b8ceff,
      #88adff,
      #437fff,
      #0051ff
      );
      background-size: 200% 200%;
    animation: animateGlow 2s linear infinite;
    z-index: 1;
    width: 100%;
    align-items: center;
    margin-top: 2px;
  }

  .legendary__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    margin: auto;
    padding: 0px;
    max-height: 20px;
    justify-items: center;
    background: linear-gradient(
      to left,
      #EEE8AA,
      #DAA520,
      #FFD700,
      #FFA500,
      #FFD700,
      #DAA520,
      #EEE8AA
      );
      background-size: 200% 200%;
    animation: animateGlow 2s linear infinite;
    z-index: 1;
    width: 100%;
    align-items: center;
    margin-top: 2px;
  }

  .ultralegendary__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    margin: auto;
    padding: 0px;
    max-height: 20px;
    justify-items: center;
    background: linear-gradient(
      to left,
      #ff5770,
      #e4428d,
      #c42da8,
      #9e16c3,
      #6501de,
      #9e16c3,
      #c42da8,
      #e4428d,
      #ff5770
      );
      background-size: 200% 200%;
    animation: animateGlow 2s linear infinite;
    z-index: 1;
    width: 100%;
    align-items: center;
    -webkit-box-shadow: 0px 0px 15px #dddddd;
    -moz-box-shadow: 0px 0px 15px #dddddd;
    box-shadow: 0px 0px 15px #dddddd;
    margin-top: 2px;
  }

  .top-left {
    position: absolute;
    top: 14px;
    left: 14px;
    color: #fff;
    z-index: 1;
    background-color: transparent;
  }

  .top-left2 {
    position: absolute;
    top: 100px;
    left: 14px;
    color: #fff;
    z-index: 1;
    background-color: transparent;
    color: #000;
  }

  .modal{
    position: fixed;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.712);
    width: 100%;
    height: 100%;
  }

  .modal__img{
    max-width: 346px;
    max-height: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 9999999999;
    margin-block: 3px;
  }

  .modal__img2{
    max-width: 280px;
    max-height: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    z-index: 9999999999;
    margin-block: 3px;
  }

  .modal__close{
    width: 24px;
    height: 24px;
    position: relative;
    cursor: pointer;
    display: flex;
    border: 2px solid #000;
    background: #000;
    margin-right: 0;
    margin-left: auto;
  }

  .bottom-center {
    position: absolute;
    bottom: 170px;
    left: 157px;
    color: #fff;
    z-index: 1;
    background-color: transparent;
  }

  .nft5__wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    margin: auto;
    padding: 0px;
    max-height: 20px;
    justify-items: center;
    color: #fff;
    z-index: 1;
    width: 100%;
    align-items: center;
  }

  .searchbar {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    margin: auto;
    padding: 0px;
    height: 40px;
    max-height: 40px;
    justify-items: center;
    color: #fff;
    z-index: 1;
    width: 70%;
    align-items: center;
    text-align: center;
  }

  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
  }

  .search__wrapper {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    margin: auto;
    padding: 0px;
    height: 40px;
    max-height: 40px;
    justify-items: center;
    color: #fff;
    z-index: 1;
    width: 100%;
    align-items: center;
  }

  .search__button {
    color: #fff;
    position: relative;
    height: 40px;
    width: 10%;
  }

  .opensea__button {
    color: #fff;
    position: relative;
    height: 40px;
    width: 100%;
  }

  .nft4__progress {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
    margin: auto;
    padding: 0px;
    max-height: 20px;
    justify-items: center;
    color: #fff;
    max-width: 125px;
    z-index: 1;
    background: rgb(195, 0, 255);
    width: 20%;
    align-items: center;
  }

  .nft3__wrapper .nft3__wrapper-text {
    width:100%;
    white-space:nowrap;
    text-overflow:clip;
    color: #fff;
  }


.cards__item__text {
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}

.cards__item__note {
  color: rgb(255, 0, 0);
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  animation: blinker 1s linear infinite;
}

.cards__item__note2 {
  color: rgb(153, 153, 153);
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  animation: blinker2 1s linear infinite;
}

.cards__item__note3 {
  color: rgb(153, 153, 153);
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}

.cards__item__header {
  color: #ffff;
  font-size: 500px;
  line-height: 50px;
  text-align: left;
}

.cards2__item__header {
  background-color: transparent;
  color: #ffff;
  font-size: 50px;
  line-height: 50px;
  text-align: left;
  position:sticky;
  top: 320px;
  z-index: 1;
}

.hidden {
  display:none;
  visibility: hidden;
}
 
.cards__item__header2 {
  color: #ffff;
  font-size: 50px;
  line-height: 50px;
  text-align: center;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 334px;
  height: 30px;
  background: rgba(0, 0, 0, 0.6);
  align-items: center;
  margin: 5px;
  position:static;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: #000;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
}

.span {
  height: 75px;
}

.picture__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.picture__item2 {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.picture__item3 {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.picture__item3__img {
  display: block;
  width: 100px;
  height: 100px;
}

.picture__item__img {
  display: block;
  width: 350px;
  height: 350px;
}

.picture__item__img2 {
  display: block;
  width: 350px;
  height: 350px;
}

.img {
  margin: 0px;
  width: 346px;
}

.select {
  background-color: #fff;
  width: 100%;
  text-align: center;
}

.option {
  background-color: #fff;
  width: 100%;
  text-align: center;
}

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

@keyframes blinker {
  2% {
    opacity: 1;
    background-color: red;
  }
}

@keyframes blinker2 {
  2% {
    opacity: 1;
    background-color: rgb(80, 80, 80);
  }
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
    display: flex;
  }
}

.sign {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  color: transparent;
  animation: shine 2s forwards, flicker 3s infinite;
  font-size: 50px;
  background-color:transparent;
  position:static;
  top: 100%;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.sign2 {
  
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  color: transparent;
  animation: shine2 2s forwards, flicker2 3s infinite;
  font-size: 40px;
  background-color:transparent;
  position:static;
  top: 100%;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483;
  }
  28%,
  33% {
    color: #ff65bd;
    text-shadow: none;
  }
  82%,
  97% {
    color: #ff2483;
    text-shadow: none;
  }
}

@keyframes blink2 {
  0%,
  22%,
  36%,
  75% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #657cff,
      -0.2rem 0.1rem 1rem #65ceff, 0.2rem 0.1rem 1rem #656fff,
      0 -0.5rem 2rem #249cff, 0 0.5rem 3rem #2495ff;
  }
  28%,
  33% {
    color: #90b7ff;
    text-shadow: none;
  }
  82%,
  97% {
    color: #24a7ff;
    text-shadow: none;
  }
}

.flicker {
  animation: shine 2s forwards, blink 3s 2s infinite;
  background-color:transparent;
}

.fast-flicker {
  animation: shine 2s forwards, blink 10s 1s infinite;
  background-color:transparent;
}

.flicker2 {
  animation: shine2 2s forwards, blink2 3s 2s infinite;
  background-color:transparent;
}

.fast-flicker2 {
  animation: shine2 2s forwards, blink2 10s 1s infinite;
  background-color:transparent;
}

.flicker3 {
  animation: shine2 1s forwards, blink2 2s 1s infinite;
  background-color:transparent;
}

.fast-flicker3 {
  animation: shine2 1s forwards, blink2 8s 2s infinite;
  background-color:transparent;
}

@keyframes shine {
  0% {
    color: #6b1839;
    text-shadow: none;
  }
  100% {
    color: #ffe6ff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
      -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
      0 -0.5rem 1rem #ff2483, 0 0.5rem 1rem #ff2483;
  }
}

@keyframes shine2 {
  0% {
    color: #18546b;
    text-shadow: none;
  }
  100% {
    color: #e6efff;
    text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #65a8ff,
      -0.2rem 0.1rem 1rem #65baff, 0.2rem 0.1rem 1rem #6581ff,
      0 -0.5rem 1rem #24a7ff, 0 0.5rem 1rem #24a7ff;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}

body {
  background-color:transparent;
  position: sticky;
  top: 320px;
  height: 100px;
  margin: 2px;
}

@keyframes shiny-btn1 {
  0% { -webkit-transform: scale(0) rotate(90deg); opacity: 0; }
  80% { -webkit-transform: scale(0) rotate(90deg); opacity: 0.5; }
  81% { -webkit-transform: scale(4) rotate(90deg); opacity: 1; }
  100% { -webkit-transform: scale(50) rotate(90deg); opacity: 0; }
}

